import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import logo from "./../../assests/logo.png";
import { useTheme, Button, IconButton } from "@mui/material";
import "./index.css";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

let Navbar = () => {
  let navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currenctPage, setCurrentPage] = useState("Home");
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <>
      {isMatch ? (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div className="main-appbar-container">
                <div className="main-appbar-content-wraper">
                  <img
                    className="_logo"
                    src={logo}
                    alt="logo"
                    onClick={() => navigate("/")}
                  />
                  <MenuIcon
                    className="app-bar-component-menu-icon"
                    onClick={() => {
                      setIsDrawerOpen(true);
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
            <div>
              <div className="app-bar-component-drawer-header-container">
                <IconButton
                  className="app-bar-component-drawer-close-btn"
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                >
                  <GrFormClose />
                </IconButton>
              </div>
              <div className="app-bar-component-drawer-nav-links-container">
                <a
                  onClick={() => navigate("/")}
                  className="_home_navbar_drawer_link"
                >
                  Home
                </a>
                           <a
                  href="http://www.periyarvision.com"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  Periyar Vision OTT
                </a>
                <a
                  href="http://www.Periyar.org"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  பெரியார்
                </a>
                <a
                  href="http://www.viduthalai.in"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  விடுதலை
                </a>
                <a
                  href="http://www.unmaionline.com"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  உண்மை
                </a>
                <a
                  href="http://www.periyarpinju.com/new/"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  பெரியார் பிஞ்சு
                </a>
                <a
                  href="http://www.periyarmatrimonial.com/"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  Matrimonial
                </a>
                <a
                  href="http://www.modernrationalist.com/"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  The Modern Rationalist
                </a>
                <a
                  href="http://www.periyar.tv/"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  Periyar TV
                </a>
                <a
                  href="http://www.dravidianbookhouse.com/"
                  target="_blank"
                  className="_home_navbar_drawer_link"
                >
                  Periyar Books
                </a>
                <a
                  onClick={() => navigate("/SearchPage")}
                  className="_home_navbar_drawer_link"
                >
                  Search
                </a>
              </div>
            </div>
          </Drawer>
        </div>
      ) : (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div className="main-appbar-container">
                <div className="main-appbar-content-wraper">
                  <img
                    className="_logo"
                    src={logo}
                    alt="logo"
                    onClick={() => navigate("/")}
                  />

                  <a
                    onClick={() => navigate("/")}
                    className="_home_navbar_link"
                  >
                    Home
                  </a>
                       <a
                  href="http://www.periyarvision.com"
                  target="_blank"
                  className="_home_navbar_link"
                >
                  Periyar Vision OTT
                </a>
                  <a
                    href="http://www.Periyar.org"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;பெரியார்&nbsp;
                  </a>
                  <a
                    href="http://www.viduthalai.in"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;விடுதலை&nbsp;
                  </a>
                  <a
                    href="http://www.unmaionline.com"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;உண்மை&nbsp;
                  </a>
                  <a
                    href="http://www.periyarpinju.com/new/"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;பெரியார் பிஞ்சு&nbsp;
                  </a>
                  <a
                    href="http://www.periyarmatrimonial.com/"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;Matrimonial&nbsp;
                  </a>
                  <a
                    href="http://www.modernrationalist.com/"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    &nbsp;The Modern Rationalist&nbsp;
                  </a>
                  <a
                    href="http://www.periyar.tv/"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    Periyar TV
                  </a>
                  <a
                    href="http://www.dravidianbookhouse.com/"
                    target="_blank"
                    className="_home_navbar_link"
                  >
                    Periyar Books
                  </a>
                  <a
                    onClick={() => navigate("/SearchPage")}
                    className="_home_navbar_link"
                  >
                    Search
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Navbar;
